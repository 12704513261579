import React, { useState } from 'react';
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { PhoneIphone } from 'styled-icons/material/PhoneIphone'
import { LaptopMac } from 'styled-icons/material/LaptopMac'
import { KeyboardArrowLeft} from 'styled-icons/material/KeyboardArrowLeft'
import { KeyboardArrowRight} from 'styled-icons/material/KeyboardArrowRight'
import { useIntl } from "gatsby-plugin-intl"
import { useSwipeable } from 'react-swipeable'

const Portfolio = () => {
    const intl = useIntl()

    const [translation, setTranslation] = useState(0);
    const translate = (value) => {
        setTranslation(translation + value);
    }

    const handlers = useSwipeable({
        onSwipedLeft: () => {translation > -1500 ? dotSlide(step + 1) : translate(0)},
        onSwipedRight: () => {translation < 0 ? dotSlide(step - 1) : translate(0)},
        preventDefaultTouchmoveEvent: true,
        trackMouse: false
    })

    const [step, setStep] = useState(0);
    const dotSlide = (value) => {
        setTranslation(value * (-300))
        setStep(value)
    }

    const data = useStaticQuery(graphql`
        query {
            shalee: file(relativePath: { eq: "portfolio/shalee.png" }) {
                childImageSharp {
                    fluid(maxHeight: 400) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            },
            bouchebee: file(relativePath: { eq: "portfolio/bouchebee.png" }) {
                childImageSharp {
                    fluid(maxHeight: 400) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            },
            cedrenchante: file(relativePath: { eq: "portfolio/cedrenchante.png" }) {
                childImageSharp {
                    fluid(maxHeight: 400) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
            squirle: file(relativePath: { eq: "portfolio/squirle.png" }) {
                childImageSharp {
                    fluid(maxHeight: 400) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
            profitroll: file(relativePath: { eq: "portfolio/profitroll.png" }) {
                childImageSharp {
                    fluid(maxHeight: 400) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
            hermes: file(relativePath: { eq: "portfolio/hermes.png" }) {
                childImageSharp {
                    fluid(maxHeight: 400) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                }
            }
        }
    `)

    return (
        <Layout>
            <SEO title="Portfolio" />
            <h1>{intl.formatMessage({ id: "PORTFOLIO.TITLE" })}</h1>
            <div className="portfolio__pagination">
                <button className={step === 0 ? "dot active" : "dot"} onClick={() => dotSlide(0)}></button>
                <button className={step === 1 ? "dot active" : "dot"} onClick={() => dotSlide(1)}></button>
                <button className={step === 2 ? "dot active" : "dot"} onClick={() => dotSlide(2)}></button>
                <button className={step === 3 ? "dot active" : "dot"} onClick={() => dotSlide(3)}></button>
                <button className={step === 4 ? "dot active" : "dot"} onClick={() => dotSlide(4)}></button>
                <button className={step === 5 ? "dot active" : "dot"} onClick={() => dotSlide(5)}></button>
            </div>
            <div className="portfolio">
                {translation < 0 && <button className="portfolio__arrow arrow-left" onClick={() => translate(800)}>
                    <KeyboardArrowLeft />
                </button>}

                <div className="portfolio__slideshow" style={{ transform:"translateX("+translation+"px)" }} {...handlers}>
                        <div className="portfolio__slideshow__slide">
                            <div className="portfolio__slideshow__slide__img">
                                <Img fluid={data.shalee.childImageSharp.fluid} imgStyle={{objectFit: 'contain'}}/>
                            </div>
                            <div className="portfolio-wrapper-description">
                                <p className="portfolio-description">{intl.formatMessage({ id: "PORTFOLIO.SHALEE_DESCRIPTION" })}</p>
                                <p><LaptopMac size={24} style={{ color: "#348AFF"}}/> {intl.formatMessage({ id: "PORTFOLIO.WEB_PLATFORM" })}</p>
                                <p><PhoneIphone size={24} style={{ color: "#348AFF"}}/> {intl.formatMessage({ id: "PORTFOLIO.MOBILE_APP" })}</p>
                            </div>
                        </div>
                        <div className="portfolio__slideshow__slide">
                            <div className="portfolio__slideshow__slide__img">
                                <Img fluid={data.bouchebee.childImageSharp.fluid} imgStyle={{objectFit: 'contain'}}/>
                            </div>
                            <div className="portfolio-wrapper-description">
                                <p className="portfolio-description">{intl.formatMessage({ id: "PORTFOLIO.BOUCHE_BEE_DESCRIPTION" })}</p>
                                <p><LaptopMac size={24} style={{ color: "#348AFF"}}/> {intl.formatMessage({ id: "PORTFOLIO.WEB_PLATFORM" })}</p>
                                <p><PhoneIphone size={24} style={{ color: "#348AFF"}}/> {intl.formatMessage({ id: "PORTFOLIO.MOBILE_APP" })}</p>
                            </div>
                        </div>
                        <div className="portfolio__slideshow__slide">
                            <div className="portfolio__slideshow__slide__img">
                                <Img fluid={data.profitroll.childImageSharp.fluid} imgStyle={{objectFit: 'contain'}}/>
                            </div>
                            <div className="portfolio-wrapper-description">
                                <p className="portfolio-description">{intl.formatMessage({ id: "PORTFOLIO.PROFITROLL_DESCRIPTION" })}</p>
                                <p><LaptopMac size={24} style={{ color: "#348AFF"}}/> {intl.formatMessage({ id: "PORTFOLIO.WEB_PLATFORM" })}</p>
                            </div>
                        </div>
                        <div  className="portfolio__slideshow__slide">
                            <div className="portfolio__slideshow__slide__img">
                                <Img fluid={data.squirle.childImageSharp.fluid} imgStyle={{objectFit: 'contain'}}/>
                            </div>
                            <div className="portfolio-wrapper-description">
                                <p className="portfolio-description">{intl.formatMessage({ id: "PORTFOLIO.SQUIRLE_DESCRIPTION" })}</p>
                                <p><LaptopMac size={24} style={{ color: "#348AFF"}}/> {intl.formatMessage({ id: "PORTFOLIO.WEB_PLATFORM" })}</p>
                            </div>
                        </div>
                        <div className="portfolio__slideshow__slide">
                            <div className="portfolio__slideshow__slide__img">
                                <Img fluid={data.cedrenchante.childImageSharp.fluid} imgStyle={{objectFit: 'contain'}}/>
                            </div>
                            <div className="portfolio-wrapper-description">
                                <p className="portfolio-description">{intl.formatMessage({ id: "PORTFOLIO.CEDRENCHANTE_DESCRIPTION" })}</p>
                                <p><LaptopMac size={24} style={{ color: "#348AFF"}}/> {intl.formatMessage({ id: "PORTFOLIO.WEB_PLATFORM" })}</p>
                            </div>
                        </div>
                        <div className="portfolio__slideshow__slide">
                            <div className="portfolio__slideshow__slide__img">
                                <Img fluid={data.hermes.childImageSharp.fluid} imgStyle={{objectFit: 'contain'}}/>
                            </div>
                            <div className="portfolio-wrapper-description">
                                <p className="portfolio-description">{intl.formatMessage({ id: "PORTFOLIO.HERMES_DESCRIPTION" })}</p>
                                <p><LaptopMac size={24} style={{ color: "#348AFF"}}/> {intl.formatMessage({ id: "PORTFOLIO.WEB_PLATFORM" })}</p>
                                <p><PhoneIphone size={24} style={{ color: "#348AFF"}}/> {intl.formatMessage({ id: "PORTFOLIO.MOBILE_APP" })}</p>
                            </div>
                        </div>
                </div>
                {translation > -4000 && <button className="portfolio__arrow arrow-right" onClick={() => translate(-800)}>
                    <KeyboardArrowRight />
                </button>}
            </div>
        </Layout>
    );
};

export default Portfolio;